.profile-section {
  position: relative;
  padding: 50px 10% 100px 8%;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.05);
}

.profile-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.profile-column._1 {
  max-width: 73%;
  padding-right: 10%;
}

.user-name-profile {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 36px;
  line-height: 40px;
  font-weight: 400;
}

.school {
  margin-bottom: 35px;
  color: rgba(53, 53, 53, 0.5);
  font-size: 16px;
}

.profile-row-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 25px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.profile-code {
  position: relative;
  width: 170px;
  margin-right: 50px;
  margin-bottom: 20px;
  padding: 15px 15px 15px 30px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 10px;
  background-color: rgba(147, 117, 251, 0.05);
}

.code-text {
  font-size: 24px;
  line-height: 30px;
}

.parent-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: rgba(53, 53, 53, 0.5);
}

.parent-row.invited {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.parent-photo {
  overflow: hidden;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 50px;
  background-image: url('/assets/images/dashboard/parent-photo.svg');
  background-position: 50% 50%;
  background-size: cover;
}

.parent-photo.uploaded {
  background-image: url('/assets/images/dashboard/Parent-Uploaded-Photo.png');
}

.user-photo-profile {
  position: relative;
  overflow: hidden;
  width: 200px;
  height: 200px;
  border-radius: 500px;
  background-image: url('/assets/images/dashboard/user-photo-profile.png');
  background-position: 50% 50%;
  background-size: cover;
}

.user-photo-profile.parent-profile-photo {
  background-image: url('/assets/images/dashboard/Bitmap-Copy-4.png');
}

.user-photo-change-bg {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 500px;
  background-color: rgba(53, 53, 53, 0.4);
  opacity: 0;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
}

.user-photo-change-bg:hover {
  opacity: 1;
}

.profile-data-text {
  padding-top: 7px;
  padding-bottom: 8px;
  color: rgba(53, 53, 53, 0.5);
  font-size: 16px;
}

.profile-data-text.password {
  padding-top: 0px;
  padding-bottom: 15px;
  font-size: 36px;
}

.user-data-form-block {
  margin-bottom: 0px;
}

.password-label {
  display: inline-block;
  overflow: hidden;
}

.parent-email {
  display: block;
  font-size: 12px;
}

.parent-name {
  display: block;
}

.parent-name-profile {
  color: #353535;
  font-size: 16px;
  font-weight: 400;
}

.exit {
  position: absolute;
  top: 30px;
  right: 3%;
  color: rgba(53, 53, 53, 0.5);
  text-align: center;
}

.exit-profile-icon {
  margin-bottom: 5px;
  margin-left: -2px;
}

.child-profile-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 40px;
  padding-bottom: 25px;
  border-top: 2px solid rgba(53, 53, 53, 0.05);
}

.child-profile-column {
  padding-right: 50px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.child-profile-photo {
  overflow: hidden;
  width: 200px;
  height: 200px;
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 300px;
  background-image: url('/assets/images/dashboard/no-photo-child.svg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.child-profile-code-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.child-profile-code-text {
  padding-right: 7px;
  color: rgba(53, 53, 53, 0.5);
  font-size: 15px;
}

.child-profile-code-column {
  position: relative;
  padding-right: 30px;
}

.child-profile-code {
  font-size: 15px;
  font-weight: 700;
}

.child-profile-data {
  padding-top: 30px;
}

.child-profile-data-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 30px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  font-size: 15px;
}

.child-profile-data-text {
  width: 100px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-weight: 400;
}

.child-profile-contact {
  color: rgba(53, 53, 53, 0.5);
}
.children-list-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 400px;
  max-width: 100%;
  margin-bottom: 45px;
  padding-top: 35px;
  padding-bottom: 25px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-bottom: 2px solid rgba(53, 53, 53, 0.05);
  font-size: 15px;
}

.children-list-text {
  width: 100px;
  margin-top: 5px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-weight: 400;
}

.children-list-photo {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 100px;
  background-image: url('/assets/images/dashboard/user-photo-profile.png');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.children-list-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.profile-row-2-column {
  width: 300px;
}

//====================================
//===============MEDIA================
//====================================
@media screen and (max-width: 991px) {
  .profile-section {
    padding-top: 30px;
    padding-right: 40px;
    padding-left: 40px;
  }
  .profile-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .profile-column._1 {
    max-width: 100%;
    padding-right: 0px;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }
  .user-name-profile {
    margin-top: 40px;
    font-size: 30px;
    line-height: 36px;
  }
  .user-name-profile.child-prof {
    margin-top: 10px;
  }
  .user-photo-profile {
    width: 150px;
    height: 150px;
  }
  .exit {
    right: 30px;
  }

  .child-profile-photo {
    width: 150px;
    height: 150px;
  }
}
//=========================768px======================
@media screen and (max-width: 768px) {
  .profile-section {
    z-index: 1;
    padding-bottom: 60px;
  }
  .user-name-profile {
    font-size: 26px;
    line-height: 32px;
  }
  .profile-row-2 {
    display: block;
  }
  .profile-code {
    margin-right: 0px;
    margin-bottom: 30px;
  }
  .child-profile-block {
    display: block;
  }
  .child-profile-photo {
    margin-bottom: 30px;
  }
  .profile-row-2-column {
    max-width: 100%;
  }
}
//========================450px=============
@media screen and (max-width: 450px) {
  .profile-section {
    padding-right: 30px;
    padding-left: 30px;
  }
  .user-name-profile {
    font-size: 22px;
    line-height: 28px;
  }
  .child-profile-code-row {
    display: block;
  }
  .child-profile-code-column {
    display: inline-block;
  }
  .child-profile-data-row {
    display: block;
  }
  .child-profile-data-text {
    width: 100%;
    margin-bottom: 10px;
  }
  .children-list-row {
    display: block;
  }
  .children-list-text {
    width: auto;
    margin-bottom: 15px;
  }
}
