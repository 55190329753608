//@import '/src/app/app.component.scss';
@import '../../styles/global-vars/colors.component.scss';

.button-blue {
  min-width: 158px;

  padding: 8px 24px;
  background: $blue_common;
  border-radius: 12px;
  font-weight: 800;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  &.inactive {
    pointer-events: none;
    background: $disabled_blue;
  }
  &:hover {
    color: #ffffff;
    background: $blue_dark;
  }
}
.button-simple {
  display: block;
  padding: 8px 26px;
  background: transparent;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #607bf7;
  border: 1px solid #b7bfe4;
  border-radius: 12px;
  text-align: center;
  transition: all 0.2s ease;
  &:hover {
    color: #3d5adf;
    border: 1px solid #3d5adf;
    transition: all 0.2s ease;
  }
  &.disabled {
    pointer-events: none;
    border: 1px solid #e7ebfe;
    color: #bfcafc;
  }
  &:active {
    border: 1px solid #a0b0fa;
    color: #a0b0fa;
  }
}

.btn {
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.6rem 1rem;
  font-size: 1rem;
  line-height: 1.25;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-grey {
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.6rem 1rem;
  font-size: 1rem;
  line-height: 1.25;
  border-radius: 17px;
  background-color: #f0f0f0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-success__active {
  font-size: 18px;
  font-weight: normal;
  line-height: 1.06;
  margin: 0 10px;
  color: #444;
  border-radius: 17px;
  background-color: #80e69b;
  &:hover {
    color: #000;
    background-color: #fff;
    border-color: #80e69b;
  }
  &:active {
    background-color: #80e69b;
    color: #fff;
  }
}

.btn-danger {
  font-weight: 600;
  margin: 0 10px;
  color: #f12143;
  border: 1px solid rgba(241, 33, 67, 0.19);
  border-radius: 3px;
  background: inherit;
  &:hover {
    color: #fff;
    background-color: #f01539;
    border-color: #f01539;
  }
  &:active {
    border: 1px solid rgba(241, 33, 67, 0.19);
    background-color: #f01539;
    color: #fff;
  }
}

.btn-warning {
  font-weight: 600;
  margin: 0 10px;
  color: #eee;
  background-color: #fda92e;
  border-color: #fda92e;
  border-radius: 3px;
  &:hover {
    color: #fda92e;
    background-color: #fff;

    border: 1px solid rgba(253, 169, 46, 0.19);
  }
  &:active {
    border: 1px solid rgba(253, 169, 46, 0.19);
    background-color: #fda92e;
    color: #fff;
  }
}

.btn-prf-outline {
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302), 0 1px 0 0 rgba(60, 64, 67, 0.149);
  transition: box-shadow 0.08s linear, min-width 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  user-select: none;
  font-size: 18px;
  letter-spacing: 0.15px;
  align-items: center;
  background: #fff none;
  border-radius: 24px;
  color: #a6a6a6;
  display: inline-flex;
  height: 48px;
  padding: 0 24px 0 0;
  text-transform: none;
  min-width: 88px;
  cursor: pointer;
  &:before {
    background: url(/assets/images/icons/plus-black.svg) no-repeat center;
    content: '';
    display: block;
    border-radius: 50%;
    height: 48px;
    margin-right: 10px;
    width: 48px;
  }
}

.avatar {
  margin: 0 10px 0 0;
  width: 44px;
  height: 44px;
  display: flex;
  overflow: hidden;
  border-radius: 50%;
  background-color: #e2e2e2;
  box-shadow: 0 2px 6px 0 hsla(0, 0%, 0%, 0.2);
  img {
    width: 44px;
  }
  span {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 20px;
    cursor: pointer;
    font-family: ALSSchlangesans, Arial, sans-serif;
    font-weight: 400;
    margin: 15% 22%;
    color: #fff;
  }
  :hover {
    cursor: pointer;
  }
}

.button {
  padding: 11px 15px;
  border-radius: 100px;
  background-color: #36ca75;
  box-shadow: 0 5px 20px 0 rgba(54, 202, 117, 0.3);
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  text-align: center;
}
.button:hover {
  color: #fff;
}
.button.notice-button {
  height: 40px;
  min-width: 190px;
  font-size: 15px;
}
.button.notice-button.no-active {
  background-color: rgba(53, 53, 53, 0.1);
  box-shadow: none;
  color: rgba(53, 53, 53, 0.5);
  cursor: default;
}
.button.achievements-button {
  display: inline-block;
}
.button.tasks-button {
  width: 180px;
  max-width: 77%;
  margin-right: 25px;
  margin-left: 25px;
  @media (max-width: 768px) {
    max-width: 90%;
    margin-right: 20px;
    margin-left: 20px;
  }

  @media (max-width: 450px) {
    margin-right: 40px;
    margin-left: 40px;
  }
}
.button.tasks-button.hidden {
  margin-top: 10px;
  margin-bottom: 30px;
}

.button.welcome-button {
  padding: 13px 45px 14px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 18px;
  line-height: 23px;
  font-weight: 400;
}

.button.welcome-button.testing {
  margin-right: 20px;
  padding-right: 24px;
  padding-left: 24px;
  @media (max-width: 768px) {
    margin-right: 0px;
    margin-bottom: 20px;
  }
}

.white-button {
  padding: 10px 25px;
  border-radius: 100px;
  background-color: #fff;
  box-shadow: 0 5px 20px 0 rgba(53, 53, 53, 0.1);
  color: #353535;
  font-weight: 700;

  @media (max-width: 450px) {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.button.welcome-button.white-button {
  padding-right: 24px;
  padding-left: 24px;
  color: rgba(53, 53, 53, 0.5);
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
}

.button.welcome-button.white-button.close-goal-lb {
  min-width: 140px;
  color: rgba(53, 53, 53, 0.8);
  font-size: 15px;
  font-weight: 700;
  @media (max-width: 768px) {
    min-width: 0px;
    font-size: 14px;
  }
}

.button.testing-button {
  margin-right: 20px;
  margin-left: 20px;
  padding: 22px 35px;
  font-size: 16px;
  @media (max-width: 450px) {
    margin-right: 10px;
    margin-left: 10px;
  }
}

.button.testing-button.next {
  padding-right: 75px;
  background-image: url('/assets/images/icons/next.svg');
  background-position: 76% 50%;
  background-repeat: no-repeat;
  @media (max-width: 450px) {
    padding-right: 55px;
    padding-left: 25px;
    background-position: 80% 50%;
    background-size: 14px;
  }
}

.button.testing-button.back {
  padding-left: 75px;
  background-image: url('/assets/images/icons/back.svg');
  background-position: 30px 50%;
  background-repeat: no-repeat;
}

.button.testing-button.back.white-button {
  color: rgba(53, 53, 53, 0.5);
  @media (max-width: 450px) {
    padding-right: 25px;
    padding-left: 55px;
    background-position: 25px 50%;
    background-size: 14px;
  }
}

.button.white-button:hover {
  color: #353535;
}
.button.white-button.class-edit-button {
  margin-right: 20px;
  color: rgba(53, 53, 53, 0.8);
  @media (max-width: 450px) {
    margin-right: 10px;
  }
}
.button.stud-page-back-btn {
  padding: 11px 21px 9px;
  background: #ffffff;
  box-shadow: 0px 5px 20px rgba(53, 53, 53, 0.1);
  border-radius: 12px;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #353535;
  opacity: 0.8;
  background-position: 20px 50%;
  background-repeat: no-repeat;
  color: rgba(53, 53, 53, 0.8);
  &:before {
    display: inline-block;
    content: url('/assets/images/icons/arrow_back.svg');
    margin-right: 10px;
  }
}

.button.add-child-button {
  margin-top: 40px;
  padding: 16px 30px;
  font-size: 15px;
  outline: none;
}

.button.add-child-button.not-active {
  background-color: rgba(53, 53, 53, 0.1);
  box-shadow: none;
  color: rgba(53, 53, 53, 0.5);
}

.button.invite-parent-button {
  margin-top: 35px;
  padding-right: 25px;
  padding-left: 25px;
}

.button.test-parent-button {
  padding: 10px 45px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 15px;
  line-height: 20px;
  font-weight: 700;
}

.button.test-parent-button.testing {
  margin-right: 20px;
  padding-right: 24px;
  padding-left: 24px;
  @media (max-width: 768px) {
    margin-right: 0px;
    margin-bottom: 20px;
  }
}
.button.test-parent-button.white-button {
  padding-right: 24px;
  padding-left: 24px;
  color: rgba(53, 53, 53, 0.8);
  line-height: 20px;
}

.return-button-container {
  position: relative;
  z-index: 999;
}
.return-button-container a {
  box-shadow: 0px 5px 20px rgba(53, 53, 53, 0.1);
  border-radius: 12px;
  background-color: #fff;
  opacity: 0.8;
  color: #353535;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  padding: 11px 38px;
  text-align: center;
  margin-bottom: 20px;
  display: inline-block;
}

.button.white-button.print-btn-2 {
  position: absolute;
  top: 10px;
  right: 50px;
  padding-left: 50px;
  background-image: url('/assets/images/icons/print.svg');
  background-position: 20px 50%;
  background-repeat: no-repeat;
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
  @media (max-width: 991px) {
    top: 27px;
    right: 20px;
  }
  @media (max-width: 768px) {
    display: none;
  }
}
.button.white-button.print-btn-2:hover {
  color: #5d7aff;
}

.button.class-edit-button {
  margin-right: 10px;
  padding: 10px 20px;
  font-size: 15px;
  line-height: 20px;
  @media (max-width: 768px) {
    padding: 6px 15px;
    font-size: 12px;
  }
  @media (max-width: 450px) {
    margin-right: 5px;
    padding-right: 10px;
    padding-left: 10px;
  }
}

.button.add-student {
  margin-top: 20px;
  margin-left: 105px;
  padding: 10px 25px;
  font-size: 15px;
  line-height: 20px;
  @media (max-width: 768px) {
    margin-left: 50px;
  }
  @media (max-width: 450px) {
    margin-left: 40px;
  }
}
.button.add-goal {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 35px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  @media (max-width: 768px) {
    max-width: 200px;
  }
}
.button.event-page-btn {
  width: 100%;
  margin-top: 35px;
  font-size: 15px;
  @media (max-width: 991px) {
    display: block;
    max-width: 335px;
  }
}
.button.event-page-btn.white-button {
  padding: 11px 15px;
  color: rgba(53, 53, 53, 0.8);
}
.button.welcome-button-stud {
  padding: 16px 50px 14px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 15px;
  line-height: 20px;
  font-weight: 700;
}

.button.welcome-button-stud.testing {
  margin-right: 20px;
  padding-right: 24px;
  padding-left: 24px;
  @media (max-width: 768px) {
    margin-right: 0px;
    margin-bottom: 20px;
  }
}

.button.welcome-button-stud.white-button {
  padding-right: 22px;
  padding-left: 22px;
  color: rgba(53, 53, 53, 0.8);
}

.button.welcome-button-stud-2 {
  padding: 10px 45px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 15px;
  line-height: 20px;
  font-weight: 700;
}

.button.welcome-button-stud-2.testing {
  margin-right: 20px;
  padding-right: 24px;
  padding-left: 24px;

  @media (max-width: 768px) {
    margin-right: 0px;
    margin-bottom: 20px;
  }
}

.button.welcome-button-stud-2.white-button {
  padding-right: 22px;
  padding-left: 22px;
  color: rgba(53, 53, 53, 0.8);
}

.button.welcome-button-stud-2.st2 {
  padding-right: 25px;
  padding-left: 25px;
}
.button.after-test {
  padding: 25px 50px;
  font-size: 18px;
  line-height: 20px;
  @media (max-width: 450px) {
    padding: 15px 30px;
  }
}
.button.attach-file-btn {
  display: block;
  font-size: 15px;
  @media (max-width: 991px) {
    max-width: 300px;
  }
}
.button.welcome-button-portfolio.white-button {
  padding: 15px 24px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: rgba(53, 53, 53, 0.5);
  @media (max-width: 768px) {
    padding-right: 25px;
    padding-left: 25px;
    font-size: 14px;
  }
}

.button.add-file {
  padding-right: 20px;
  padding-left: 20px;
}

.button.add-achieve-btn {
  padding-right: 40px;
  padding-left: 40px;
  font-size: 15px;

  @media (max-width: 450px) {
    padding-right: 25px;
    padding-left: 25px;
    font-size: 14px;
  }
}

.button.add-achieve-btn.no-active {
  background-color: rgba(53, 53, 53, 0.1);
  box-shadow: none;
  color: rgba(53, 53, 53, 0.5);
  cursor: default;
}
.button.save-achieve {
  min-width: 140px;
  margin-right: 10px;
  font-size: 15px;
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
}

.button.save-achieve.not-active {
  background-color: rgba(53, 53, 53, 0.1);
  color: rgba(53, 53, 53, 0.5);
}
.button.green-btn {
  min-width: 242px;
  margin-right: 20px;
  margin-bottom: 15px;
  padding: 16px 35px;
  background-color: #36ca75;
  box-shadow: 0 5px 20px 0 rgba(54, 202, 117, 0.3);
  font-size: 15px;
  text-align: center;

  @media (max-width: 991px) {
    min-width: auto;
    padding-right: 25px;
    padding-left: 25px;
    font-size: 14px;
  }
}
.button.edit-event-page-btn {
  padding-right: 30px;
  padding-left: 30px;
  font-size: 15px;
}

.user-photo-change-button {
  padding: 10px 20px;
  border-radius: 100px;
  background-color: #fff;
  box-shadow: 0 5px 20px 0 rgba(53, 53, 53, 0.5);
  color: #353535;
  font-size: 16px;
}
.edit-profile-data-button {
  margin-top: 15px;
  padding: 10px 30px 10px 50px;
  border-radius: 100px;
  background-color: #fff;
  background-image: url('/assets/images/icons/edit-icon.svg');
  background-position: 20px 50%;
  background-repeat: no-repeat;
  box-shadow: 0 5px 20px 0 rgba(53, 53, 53, 0.1);
  color: #353535;
  font-size: 16px;
  text-align: center;
}

.edit-profile-data-buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.button.save-profile-changes-button {
  margin-right: 20px;
  padding: 10px 30px;
  @media (max-width: 450px) {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.white-button.edit-profile-data-button {
  padding-left: 50px;
  font-weight: 500;
}

.white-button.show-more {
  color: rgba(53, 53, 53, 0.8);
}

.white-button.change-event-photo {
  position: relative;
  z-index: 1;
  font-size: 16px;
  font-weight: 500;
}

.white-button.edit-event {
  margin-bottom: 15px;
  padding: 16px 35px;
  color: rgba(53, 53, 53, 0.8);
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  @media (max-width: 991px) {
    padding-right: 25px;
    padding-left: 25px;
    font-size: 14px;
  }
}

.white-button.edit-event:hover {
  color: #5d7aff;
}
.main-screen-buttons-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.class-buttons-row {
  position: absolute;
  right: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  @media (max-width: 450px) {
    .class-buttons-row {
      position: static;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
    }
  }
}
.goal-button-icon {
  position: relative;
  top: 1px;
  margin-right: 15px;
}
.play-button {
  position: absolute;
  left: 50%;
  top: 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 80px;
  height: 80px;
  padding-left: 5px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.reg-form-back-button {
  display: inline-block;
  padding-left: 20px;
  background-image: url('/assets/images/icons/back-arrow.svg');
  background-position: 0px 50%;
  background-repeat: no-repeat;
  color: rgba(53, 53, 53, 0.5);
  font-size: 15px;
  font-weight: 700;
}

.reg-form-back-button:hover {
  background-image: url('/assets/images/icons/back-arrow-hover.svg');
}

.button-new {
  display: inline-block;
  padding: 16px 20px;
  min-width: 235px;
  border-radius: 100px;
  background: #67c77d;
  box-shadow: 0px 0px 24px rgba(103, 199, 125, 0.48);
  color: #fff;
  font-weight: 900;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  &:hover {
    color: #fff;
  }
  @media (max-width: 768px) {
    padding: 14px 18px;
  }
}
.button-registrate {
  display: inline-block;
  padding: 16px 20px;
  width: 100%;
  max-width: 381px;
  border-radius: 100px;
  background: $green_new;
  color: #fff;
  font-weight: 900;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  pointer-events: auto;
  transition: all 0.2s ease;
  &.disabled {
    background: #eaeaeb;
    color: #909090;
    pointer-events: none;
    box-shadow: none;
  }
}

.admin-add-event {
  position: relative;
  display: inline-block;
  padding: 10px 25px;
  border-radius: 100px;
  background-color: #36ca75;
  box-shadow: 0 5px 20px 0 rgba(54, 202, 117, 0.3);
  color: #fff;
  font-size: 15px;
  line-height: 20px;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
}

.admin-add-event:hover {
  color: #fff;
}

.admin-add-event.w--current {
  background-color: #9375fb;
  color: #fff;
}

.admin-add-event.no-active {
  color: rgba(53, 53, 53, 0.2);
  cursor: default;
}

.event-status-edit-btn {
  position: absolute;
  top: 50%;
  right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  background-color: #9375fb;
  background-image: url('/assets/images/icons/edit-btn-white.svg');
  background-position: 50% 50%;
  background-repeat: no-repeat;
  box-shadow: 0 5px 20px 0 rgba(147, 117, 251, 0.3);
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
}

.event-status-edit-btn.short {
  right: 30px;
}

.edit-event-button {
  margin-left: 30px;
  @media (max-width: 450px) {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

/*===== disabled buttons ======*/
.disabled {
  background-color: #ebebeb;
  pointer-events: none;
  color: rgba(53, 53, 53, 0.5);
  box-shadow: 0 5px 20px 0 rgba(53, 53, 53, 0.3);
}

.disabled-filter {
  pointer-events: none;
  color: rgba(53, 53, 53, 0.5) !important;
}

/*======== BUTTON ANIMATE ON ========*/
.w-center-content {
  text-align: center;
}

.button-animate {
  max-width: 100%;
  min-width: 180px;
  margin-top: 32px;
  text-decoration: none;
  line-height: 48px;
  padding: 0 30px 0 55px;
  position: relative;
  text-align: center;
  display: inline-block;
  background-color: #36ca75;
  color: #fff;
  font-weight: 500;
  border-radius: 23px;
  font-size: 16px;
  transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s ease;
  overflow: hidden;
}

.btn-icon {
  width: 24px;
  height: 24px;
  background-color: #fff;
  border: 0 solid #36ca75;
  border-radius: 50%;
  display: inline-block;
  top: 11px;
  position: absolute;
  left: 20px;
}

.button-animate .btn-icon:before {
  content: '';
  left: 0;
  top: 0;
  position: absolute;
  transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s ease;
}

.button-animate:hover {
  color: #fff;
}

.btn-activate .btn-icon:before {
  width: 24px;
  height: 24px;
  background-image: url('data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjI0cHgiIGhlaWdodD0iMjRweCIgdmlld0JveD0iMCAwIDI2OC44MzEgMjY4LjgzMiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjY4LjgzMSAyNjguODMyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBhdGggZD0iTTIyMy4yNTUsODMuNjU5bC04MC03OS45OThjLTQuODgxLTQuODgxLTEyLjc5Ny00Ljg4MS0xNy42NzgsMGwtODAsODBjLTQuODgzLDQuODgyLTQuODgzLDEyLjc5NiwwLDE3LjY3OCAgIGMyLjQzOSwyLjQ0LDUuNjQsMy42NjEsOC44MzksMy42NjFzNi4zOTctMS4yMjEsOC44MzktMy42NjFsNTguNjYxLTU4LjY2MXYyMTMuNjU0YzAsNi45MDMsNS41OTcsMTIuNSwxMi41LDEyLjUgICBjNi45MDEsMCwxMi41LTUuNTk3LDEyLjUtMTIuNVY0Mi42NzdsNTguNjYxLDU4LjY1OWM0Ljg4Myw0Ljg4MSwxMi43OTcsNC44ODEsMTcuNjc4LDAgICBDMjI4LjEzNyw5Ni40NTUsMjI4LjEzNyw4OC41NDEsMjIzLjI1NSw4My42NTl6IiBmaWxsPSIjMzE5YmVmIi8+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==');
  background-repeat: no-repeat;
  background-size: 10px;
  background-position-x: center;
  background-position-y: center;
}

.button-animate .btn-icon:after {
  content: '';
  top: 0;
  left: 0;
  position: absolute;
  transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
}

.btn-activate .btn-icon:after {
  width: 24px;
  height: 24px;
  background-image: url('data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHZlcnNpb249IjEuMSIgdmlld0JveD0iMCAwIDI2IDI2IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAyNiAyNiIgd2lkdGg9IjI0cHgiIGhlaWdodD0iMjRweCI+CiAgPHBhdGggZD0ibS4zLDE0Yy0wLjItMC4yLTAuMy0wLjUtMC4zLTAuN3MwLjEtMC41IDAuMy0wLjdsMS40LTEuNGMwLjQtMC40IDEtMC40IDEuNCwwbC4xLC4xIDUuNSw1LjljMC4yLDAuMiAwLjUsMC4yIDAuNywwbDEzLjQtMTMuOWgwLjF2LTguODgxNzhlLTE2YzAuNC0wLjQgMS0wLjQgMS40LDBsMS40LDEuNGMwLjQsMC40IDAuNCwxIDAsMS40bDAsMC0xNiwxNi42Yy0wLjIsMC4yLTAuNCwwLjMtMC43LDAuMy0wLjMsMC0wLjUtMC4xLTAuNy0wLjNsLTcuOC04LjQtLjItLjN6IiBmaWxsPSIjMmY4OWQxIi8+Cjwvc3ZnPgo=');
  background-repeat: no-repeat;
  background-size: 8px;
  background-position-x: center;
  background-position-y: 34px;
}

.btn-activate:hover {
  background-color: #36ca75;
}

.btn-activate:hover .btn-icon {
  border-color: #36ca75;
}

.btn-activate:hover .btn-icon:before {
  background-position-y: -34px;
}

.btn-activate:hover .btn-icon:after {
  background-position-y: center;
}

.btn-waiting {
  background-color: #36ca75;
}

.btn-waiting .btn-icon {
  background-color: transparent;
}

.btn-waiting .btn-icon:after {
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  border-radius: 50%;
  animation: rotation infinite linear 0.5s;
  transition: none;
  border-top: 2px solid transparent;
  border-left: 2px solid #fff;
  border-right: 2px solid transparent;
  border-bottom: 2px solid transparent;
  z-index: 0;
}

.btn-activated {
  background-color: #36ca75;
  box-shadow: 0 5px 20px 0 rgba(54, 202, 117, 0.3);
}

.btn-activated .btn-icon:after {
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHZlcnNpb249IjEuMSIgdmlld0JveD0iMCAwIDI2IDI2IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAyNiAyNiIgd2lkdGg9IjI0cHgiIGhlaWdodD0iMjRweCI+CiAgPHBhdGggZD0ibS4zLDE0Yy0wLjItMC4yLTAuMy0wLjUtMC4zLTAuN3MwLjEtMC41IDAuMy0wLjdsMS40LTEuNGMwLjQtMC40IDEtMC40IDEuNCwwbC4xLC4xIDUuNSw1LjljMC4yLDAuMiAwLjUsMC4yIDAuNywwbDEzLjQtMTMuOWgwLjF2LTguODgxNzhlLTE2YzAuNC0wLjQgMS0wLjQgMS40LDBsMS40LDEuNGMwLjQsMC40IDAuNCwxIDAsMS40bDAsMC0xNiwxNi42Yy0wLjIsMC4yLTAuNCwwLjMtMC43LDAuMy0wLjMsMC0wLjUtMC4xLTAuNy0wLjNsLTcuOC04LjQtLjItLjN6IiBmaWxsPSIjNDRjYzcxIi8+Cjwvc3ZnPgo=);
  width: 24px;
  height: 24px;
  background-size: 10px;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  animation: activated 0.3s linear 1;
}

.btn-text {
  position: relative;
}

.btn-text:before {
  content: attr(data-after);
  position: absolute;
  top: -27px;
  color: transparent;
  z-index: -1;
  color: #fff;
  transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
}

.btn-text:after {
  content: attr(data-wait);
  position: absolute;
  color: transparent;
  top: 2px;
  left: 0;
  z-index: -1;
  color: #fff;
  transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
}

.btn-waiting .btn-text {
  color: transparent;
}

.btn-waiting .btn-text:after {
  top: -15px;
  z-index: 1;
}

.btn-activated .btn-text:before {
  top: -15px;
  z-index: 1;
}

.btn-activated .btn-text {
  color: transparent;
}

@keyframes rotation {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

@keyframes activated {
  0% {
    background-position-y: 34px;
  }
  100% {
    background-position-y: center;
  }
}

/*======== BUTTON ANIMATE END ========*/

/*======== BUTTON ANIMATE ON purple ========*/

.button-animate-purple {
  max-width: 100%;
  min-width: 180px;
  margin-top: 20px;
  text-decoration: none;
  line-height: 48px;
  padding: 0 30px 0 55px;
  position: relative;
  text-align: center;
  display: inline-block;
  background-color: #9375fb;
  color: #fff;
  font-weight: 500;
  border-radius: 23px;
  font-size: 16px;
  transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s ease;
  overflow: hidden;
}

.btn-icon-purple {
  width: 24px;
  height: 24px;
  background-color: #fff;
  border: 0 solid #9375fb;
  border-radius: 50%;
  display: inline-block;
  top: 11px;
  position: absolute;
  left: 20px;
}

.button-animate-purple .btn-icon-purple:before {
  content: '';
  left: 0;
  top: 0;
  position: absolute;
  transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s ease;
}

.button-animate-purple:hover {
  color: #fff;
}

.btn-activate-purple .btn-icon-purple:before {
  width: 24px;
  height: 24px;
  background-image: url('data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjI0cHgiIGhlaWdodD0iMjRweCIgdmlld0JveD0iMCAwIDI2OC44MzEgMjY4LjgzMiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjY4LjgzMSAyNjguODMyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBhdGggZD0iTTIyMy4yNTUsODMuNjU5bC04MC03OS45OThjLTQuODgxLTQuODgxLTEyLjc5Ny00Ljg4MS0xNy42NzgsMGwtODAsODBjLTQuODgzLDQuODgyLTQuODgzLDEyLjc5NiwwLDE3LjY3OCAgIGMyLjQzOSwyLjQ0LDUuNjQsMy42NjEsOC44MzksMy42NjFzNi4zOTctMS4yMjEsOC44MzktMy42NjFsNTguNjYxLTU4LjY2MXYyMTMuNjU0YzAsNi45MDMsNS41OTcsMTIuNSwxMi41LDEyLjUgICBjNi45MDEsMCwxMi41LTUuNTk3LDEyLjUtMTIuNVY0Mi42NzdsNTguNjYxLDU4LjY1OWM0Ljg4Myw0Ljg4MSwxMi43OTcsNC44ODEsMTcuNjc4LDAgICBDMjI4LjEzNyw5Ni40NTUsMjI4LjEzNyw4OC41NDEsMjIzLjI1NSw4My42NTl6IiBmaWxsPSIjMzE5YmVmIi8+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==');
  background-repeat: no-repeat;
  background-size: 10px;
  background-position-x: center;
  background-position-y: center;
}

.button-animate-purple .btn-icon-purple:after {
  content: '';
  top: 0;
  left: 0;
  position: absolute;
  transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
}

.btn-activate-purple .btn-icon-purple:after {
  width: 24px;
  height: 24px;
  background-image: url('data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHZlcnNpb249IjEuMSIgdmlld0JveD0iMCAwIDI2IDI2IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAyNiAyNiIgd2lkdGg9IjI0cHgiIGhlaWdodD0iMjRweCI+CiAgPHBhdGggZD0ibS4zLDE0Yy0wLjItMC4yLTAuMy0wLjUtMC4zLTAuN3MwLjEtMC41IDAuMy0wLjdsMS40LTEuNGMwLjQtMC40IDEtMC40IDEuNCwwbC4xLC4xIDUuNSw1LjljMC4yLDAuMiAwLjUsMC4yIDAuNywwbDEzLjQtMTMuOWgwLjF2LTguODgxNzhlLTE2YzAuNC0wLjQgMS0wLjQgMS40LDBsMS40LDEuNGMwLjQsMC40IDAuNCwxIDAsMS40bDAsMC0xNiwxNi42Yy0wLjIsMC4yLTAuNCwwLjMtMC43LDAuMy0wLjMsMC0wLjUtMC4xLTAuNy0wLjNsLTcuOC04LjQtLjItLjN6IiBmaWxsPSIjMmY4OWQxIi8+Cjwvc3ZnPgo=');
  background-repeat: no-repeat;
  background-size: 8px;
  background-position-x: center;
  background-position-y: 34px;
}

.btn-activate-purple:hover {
  background-color: #9375fb;
}

.btn-activate-purple:hover .btn-icon-purple {
  border-color: #9375fb;
}

.btn-activate-purple:hover .btn-icon-purple:before {
  background-position-y: -34px;
}

.btn-activate-purple:hover .btn-icon-purple:after {
  background-position-y: center;
}

.btn-waiting-purple {
  background-color: #9375fb;
}

.btn-waiting-purple .btn-icon-purple {
  background-color: transparent;
}

.btn-waiting-purple .btn-icon-purple:after {
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  border-radius: 50%;
  animation: rotation infinite linear 0.5s;
  transition: none;
  border-top: 2px solid transparent;
  border-left: 2px solid #fff;
  border-right: 2px solid transparent;
  border-bottom: 2px solid transparent;
  z-index: 0;
}

.btn-activated-purple {
  background-color: #9375fb;
  box-shadow: 0 5px 20px 0 rgba(147, 117, 251, 0.3);
}

.btn-activated-purple .btn-icon-purple:after {
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHZlcnNpb249IjEuMSIgdmlld0JveD0iMCAwIDI2IDI2IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAyNiAyNiIgd2lkdGg9IjI0cHgiIGhlaWdodD0iMjRweCI+CiAgPHBhdGggZD0ibS4zLDE0Yy0wLjItMC4yLTAuMy0wLjUtMC4zLTAuN3MwLjEtMC41IDAuMy0wLjdsMS40LTEuNGMwLjQtMC40IDEtMC40IDEuNCwwbC4xLC4xIDUuNSw1LjljMC4yLDAuMiAwLjUsMC4yIDAuNywwbDEzLjQtMTMuOWgwLjF2LTguODgxNzhlLTE2YzAuNC0wLjQgMS0wLjQgMS40LDBsMS40LDEuNGMwLjQsMC40IDAuNCwxIDAsMS40bDAsMC0xNiwxNi42Yy0wLjIsMC4yLTAuNCwwLjMtMC43LDAuMy0wLjMsMC0wLjUtMC4xLTAuNy0wLjNsLTcuOC04LjQtLjItLjN6IiBmaWxsPSIjNDRjYzcxIi8+Cjwvc3ZnPgo=);
  width: 24px;
  height: 24px;
  background-size: 10px;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  animation: activated 0.3s linear 1;
}

.btn-text-purple {
  position: relative;
}

.btn-text-purple:before {
  content: attr(data-after);
  position: absolute;
  top: -27px;
  color: transparent;
  z-index: -1;
  color: #fff;
  transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
}

.btn-text-purple:after {
  content: attr(data-wait);
  position: absolute;
  color: transparent;
  top: 2px;
  left: 0;
  z-index: -1;
  color: #fff;
  transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
}

.btn-waiting-purple .btn-text-purple {
  color: transparent;
}

.btn-waiting-purple .btn-text-purple:after {
  top: -15px;
  z-index: 1;
}

.btn-activated-purple .btn-text-purple:before {
  top: -15px;
  z-index: 1;
}

.btn-activated-purple .btn-text-purple {
  color: transparent;
}

@keyframes rotation {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

@keyframes activated {
  0% {
    background-position-y: 34px;
  }
  100% {
    background-position-y: center;
  }
}

/*======== BUTTON ANIMATE END ========*/

//====================================
//===============MEDIA================
//====================================
@media screen and (max-width: 991px) {
}
@media screen and (max-width: 768px) {
  .main-screen-buttons-row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .main-screen-buttons-row.goal-lb {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .admin-add-event {
    padding-right: 15px;
    padding-left: 15px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
  }
  .return-button-container {
    margin-left: 60px;
    margin-top: -40px;
  }
}

//============================450px===============
@media screen and (max-width: 450px) {
  .button-blue {
    max-width: 260px;
    width: 240px;
    padding: 8px 24px;
    width: 100%;
    font-weight: 800;
    font-size: 14px;
    line-height: 24px;
  }
  .return-button-container {
    margin-top: -70px;
  }
}
