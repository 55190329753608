//=====LOGIN / REGISTRATION=======
.login-container {
  position: relative;
  left: 50%;
  width: 474px;
  max-width: 100%;
  margin-top: 15vh;
  margin-bottom: 110px;
  -webkit-transform: translate(-50%, 0px);
  -ms-transform: translate(-50%, 0px);
  transform: translate(-50%, 0px);
  text-align: center;
}

.tab-pane.login {
  display: block;
  padding: 40px 46px 48px;
}

.login-section {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.login-label {
  display: flex;
  width: 170px;
  margin-bottom: 0px;
  padding-top: 6px;
  padding-right: 30px;
  align-items: center;
  flex: 0 0 auto;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  text-align: left;
}

.login-form-header {
  display: flex;
  flex-direction: column;
  align-items: center;

  .login-form-title {
    margin-bottom: 30px;
    font-weight: 900;
    font-size: 22px;
    line-height: 30px;
  }
}

.login-form-footer {
  .footer_separator {
    margin: 17px 0;
    position: relative;
    font-weight: 400;
    font-size: 11px;
    line-height: 15px;
    text-align: center;
    color: #353535;
    &:before,
    &:after {
      position: absolute;
      content: '';
      width: calc(50% - 28px);
      height: 2px;
      top: 7px;
      background: #f4f5f4;
    }
    &:before {
      left: 0;
    }
    &:after {
      right: 0;
    }
  }
  .footer_sign-in {
    margin-top: 16px;
    font-weight: normal;
    font-size: 11px;
    line-height: 15px;
    color: $color-black2;
    text-align: center;
    .sign-in-link {
      margin-left: 8px;
      text-decoration: underline;
    }
  }
}
.prosvet-registration {
  text-align: center;
  margin-top: 32px;
  .prosvet-link {
    position: relative;
    color: #c7c7c7;
    &:before {
      content: url('/assets/images/prosvet/login_icon_prosvet.svg');
      margin-right: 5px;
    }
  }
}

.login-field {
  height: auto;
  max-width: 100%;
  margin-bottom: 0px;
  padding: 6px 0px 4px;
  border-style: solid;
  border-color: transparent transparent hsla(0, 0%, 59%, 0.1);
  border-bottom-width: 2px;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #353535;
  font-size: 15px;
}

.login-field:hover {
  border-bottom-color: $blue_common;
}

.login-field:focus {
  border-color: transparent transparent $blue_dark;
}

.login-field::-webkit-input-placeholder {
  color: #353535;
}

.login-field:-ms-input-placeholder {
  color: #353535;
}

.login-field::-ms-input-placeholder {
  color: #353535;
}

.login-field::placeholder {
  color: #353535;
}

.login-field.invite-field {
  width: 350px;
  font-size: 24px;
}

.login-form-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 22px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.login-form-row.reg-form {
  margin-top: -20px;
  margin-bottom: 30px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.login-field-column {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.login-field-column.reg-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.registration-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: -20px;
  margin-bottom: -20px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.registration-column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  padding-right: 20px;
  padding-bottom: 20px;
}

.registration-link {
  padding: 28px 10px 26px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-radius: 10px;
  background-color: rgba(53, 53, 53, 0.02);
  color: #353535;
  text-align: center;
}

.registration-link-image {
  overflow: hidden;
  width: 90px;
  height: 90px;
  border-radius: 150px;
}

.registration-link-image.reg-form {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.registration-link-text {
  margin-top: 15px;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.2px;
}

.registration-link-text.reg-form {
  margin-top: 0px;
}

.login-section {
  overflow: hidden;
}

.policy-text {
  position: relative;
  margin-top: 30px;
  font-size: 13px;
}

.login-form-column {
  width: 150px;
  padding-right: 45px;
}

//-------------NEW LOGIN---------------

.login-data {
  margin-right: 10%;
}
.login-picture {
  img {
    min-width: 455px;
    height: auto;
  }
}

.login-content {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.h1-login-text {
  margin-bottom: 62px;
  font-weight: 500;
  font-size: 44px;
  line-height: 120%;
  color: #353535;
  text-align: left;
}
.login-text {
  margin-top: 6px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #353535;
  text-align: left;
}

.login-form-container {
  min-width: 400px;
  margin: 0 auto;
  width: 515px;
  padding: 46px;
  background: rgba(231, 235, 254, 0.3);
  border-radius: 24px;
}

.promt-link-wrapper {
  position: relative;
  display: block;
}

.login-form-block {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.login-input-container {
  width: 100%;
  padding-top: 4px;
  position: relative;
  .login-label {
    position: absolute;
    top: 24px;
    padding: 0;
    color: rgba(53, 53, 53, 0.4);
    font-weight: 500;
    font-size: 24px;
    line-height: 33px;
    color: #353535;
    opacity: 0.3;
    transition: all 0.2s ease;
    &.topped-out {
      top: 0;
      margin-bottom: 0;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #353535;
      opacity: 0.3;
      transition: all 0.2s ease;
    }
  }
  .input-wrapper {
    position: relative;
    .login-input {
      padding: 6px 0 10px;
      margin: 0;
      margin-top: 14px;
      height: auto;
      max-width: 100%;
      background: transparent;
      border-color: transparent transparent rgba(61, 90, 223, 0.3);
      border-style: solid;
      border-bottom-width: 2px;
      -webkit-transition: all 200ms ease;
      transition: all 200ms ease;
      color: #353535;
      font-weight: 500;
      font-size: 24px;
      line-height: 33px;
      color: #353535;
      &:-webkit-autofill {
        transition: background-color 5000s ease-in-out 0s;
      }
      &.error-field {
        color: #ff5722;
        border-bottom-color: #ff5722;
        &:-webkit-autofill {
          -webkit-text-fill-color: #ff5722 !important;
        }
        & ~ .input-clear {
          svg {
            path {
              stroke: #ff5722;
            }
          }
        }
      }
    }
  }
}
.w-input[readonly] {
  background: unset;
}
.w-input[disabled] {
  background-color: #ffffff;
}

.alert-message {
  margin-top: 6px;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #ff5722;
  text-align: left;
}

.promt-text {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  margin-left: auto;
  font-size: 18px;
  line-height: 25px;
}
.promt-link {
  font-weight: normal;
  color: #3e60fd;
  transition: all 0.2s ease;
  &.inactive {
    pointer-events: none;
    color: #353535;
    opacity: 0.4;
    transition: all 0.2s ease;
  }
}
.timer-value {
  font-weight: 500;
  color: #3e60fd;
  margin-right: 12px;
}

.info-exacting {
  .info-pswrd-icon {
    background: url(/assets/images/icons/checkIcon-blue.svg) no-repeat left;
  }

  .info-pswrd-icon.not-correct {
    background: url(/assets/images/icons/checkIcon-gray.svg) no-repeat left;
  }
}

.submit-button {
  margin-top: 42px;
  width: 100%;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  // &.disabled {
  //   pointer-events: none;
  //   background: #e7ebfe;
  // }
}

.alert-message-link {
  color: #ff5722;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: #607bf7;
  }
}

//================================================
//==================MEDIA=========================
//================================================

@media screen and (max-width: 1024px) {
  .login-data {
    margin: 0 auto;
  }
  .login-picture {
    display: none;
  }
}
@media screen and (max-width: 991px) {
}
//=============»========768px================
@media screen and (max-width: 768px) {
  .tab-pane.login {
    padding-top: 40px;
    padding-right: 40px;
    padding-left: 40px;
  }
  .login-field.invite-field {
    font-size: 14px;
  }
  .login-form-row.reg-form {
    margin-top: 0px;
  }
  .policy-text {
    margin-right: 20px;
    margin-left: 20px;
  }

  .h1-login-text {
    margin: 0px 0 40px;
    font-size: 28px;
    line-height: 34px;
  }

  .login-form-block {
    margin-top: 46px;
    .login-input-container {
      padding: 6px 0 8px;
      .login-label {
        top: 30px;

        font-size: 16px;
        line-height: 20px;
        &.topped-out {
          top: 6px;
          font-weight: normal;
          font-size: 14px;
          line-height: 18px;
        }
      }
      .input-wrapper {
        .login-input {
          font-size: 16px;
          line-height: 20px;
          &.error-field {
            &:-webkit-autofill {
            }
          }
        }
        .input-clear {
          img {
          }
        }
      }
    }
  }
  .promt-text {
    font-size: 16px;
    line-height: 20px;
  }
  .promt-link {
    transition: all 0.2s ease;
    &.inactive {
      pointer-events: none;
      color: #353535;
      opacity: 0.4;
      transition: all 0.2s ease;
    }
  }
  .timer-value {
    font-size: 16px;
    line-height: 20px;
  }
}
//================450зч===============
@media screen and (max-width: 450px) {
  .login-container {
    margin-top: 130px;
    margin-bottom: 0;
  }

  .tab-pane.login {
    padding-top: 24px;
    padding-right: 16px;
    padding-left: 16px;
    padding-bottom: 32px;
  }
  .login-section {
    background-color: #fcfbf8;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .login-title._mobile {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 56px;
    font-weight: 900;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #353535;
  }

  .login-text._mobile {
    position: absolute;
    width: 100%;
    top: 90px;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
  }

  .login-label {
    width: auto;
    margin-bottom: 3px;
    padding-top: 0px;
    padding-right: 0px;
  }

  .login-form-header {
    .login-form-title {
    }
  }

  .login-form-row {
    margin-bottom: 20px;
  }

  .login-form-footer {
    margin-top: 24px;
    .footer_separator {
      margin: 16px 0;
    }
    .footer_sign-in {
      font-weight: normal;
      font-size: 11px;
      line-height: 15px;
      color: #353535;
      .sign-in-link {
      }
    }
  }
  .login-form-row {
    display: block;
  }
  .login-form-row.reg-form {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .registration-link-image {
    width: 60px;
    height: 60px;
  }
  .login-form-column {
    width: auto;
  }

  .h1-login-text {
    margin: 0 17px 74px;
    font-weight: 800;
    font-size: 28px;
    line-height: 38px;
  }

  .login-form-container {
    padding: 34px 17px 26px;
    max-width: 330px;
    min-width: unset;
    @media (max-width: 350px) {
      max-width: 288px;
    }
  }

  .login-text {
    font-size: 14px;
    line-height: 19px;
  }

  .login-form-block {
    margin-top: 46px;
    .login-input-container {
      padding: 5px 0 7px;
      .login-label {
        top: 28px;
        font-weight: 800;
        font-size: 14px;
        line-height: 19px;
        &.topped-out {
          top: 5px;
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
        }
      }
      .input-wrapper {
        .login-input {
          font-weight: 800;
          font-size: 14px;
          line-height: 19px;
          &.error-field {
            &:-webkit-autofill {
            }
          }
        }
        .input-clear {
          img {
          }
        }
      }
    }
  }
  .promt-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
  }
  .promt-link {
    font-weight: 500;
    transition: all 0.2s ease;
    &.inactive {
      pointer-events: none;
      color: #353535;
      opacity: 0.4;
      transition: all 0.2s ease;
    }
  }
  .timer-value {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
  }
}
