//-----------------KZAstanaPro----------------------------------------------------
@font-face {
  font-family: 'KZAstanaPro';
  src: url('/assets/fonts/KZAstanaPro-Regular/KZ_Astana_Pro-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'KZAstanaPro';
  src: url('/assets/fonts/KZAstanaPro-Bold/KZ_Astana_Pro-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

//---------------Avenirnextcyr--------------------------------------------------------
@font-face {
  font-family: 'Avenirnextcyr';
  src: url('/assets/fonts/AvenirNextCyr-Regular/avenirnextcyr-regular.eot');
  src: url('/assets/fonts/AvenirNextCyr-Regular/avenirnextcyr-regular.woff2') format('woff2'),
    url('/assets/fonts/AvenirNextCyr-Regular/avenirnextcyr-regular.woff') format('woff'),
    url('/assets/fonts/AvenirNextCyr-Regular/avenirnextcyr-regular.ttf') format('truetype'),
    url('/assets/fonts/AvenirNextCyr-Regular/avenirnextcyr-regular.svg#Avenirnextcyr') format('svg'),
    url('/assets/fonts/AvenirNextCyr-Regular/avenirnextcyr-regular.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Avenirnextcyr';
  src: url('/assets/fonts/AvenirNextCyr-Medium/avenirnextcyr-medium.eot');
  src: url('/assets/fonts/AvenirNextCyr-Medium/avenirnextcyr-medium.woff2') format('woff2'),
    url('/assets/fonts/AvenirNextCyr-Medium/avenirnextcyr-medium.woff') format('woff'),
    url('/assets/fonts/AvenirNextCyr-Medium/avenirnextcyr-medium.ttf') format('truetype'),
    url('/assets/fonts/AvenirNextCyr-Medium/avenirnextcyr-medium.svg#Avenirnextcyr-Medium') format('svg'),
    url('/assets/fonts/AvenirNextCyr-Medium/avenirnextcyr-medium.eot?#iefix') format('embedded-opentype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Avenirnextcyr-Demi';
  src: url('/assets/fonts/AvenirNextCyr-Demi/avenirnextcyr-demi.eot');
  src: url('/assets/fonts/AvenirNextCyr-Demi/avenirnextcyr-demi.woff2') format('woff2'),
    url('/assets/fonts/AvenirNextCyr-Demi/avenirnextcyr-demi.woff') format('woff'),
    url('/assets/fonts/AvenirNextCyr-Demi/avenirnextcyr-demi.ttf') format('truetype'),
    url('/assets/fonts/AvenirNextCyr-Demi/avenirnextcyr-demi.svg#Avenirnextcyr-Demi') format('svg'),
    url('/assets/fonts/AvenirNextCyr-Demi/avenirnextcyr-demi.eot?#iefix') format('embedded-opentype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Avenirnextcyr';
  src: url('/assets/fonts/AvenirNextCyr-Bold/avenirnextcyr-bold.eot');
  src: url('/assets/fonts/AvenirNextCyr-Bold/avenirnextcyr-bold.woff2') format('woff2'),
    url('/assets/fonts/AvenirNextCyr-Bold/avenirnextcyr-bold.woff') format('woff'),
    url('/assets/fonts/AvenirNextCyr-Bold/avenirnextcyr-bold.ttf') format('truetype'),
    url('/assets/fonts/AvenirNextCyr-Bold/avenirnextcyr-bold.svg#Avenirnextcyr-Bold') format('svg'),
    url('/assets/fonts/AvenirNextCyr-Bold/avenirnextcyr-bold.eot?#iefix') format('embedded-opentype');
  font-weight: 700;
  font-style: normal;
}

//----------------------Avenir Condensed----------------------------------------------
//problems with cyrillic, check before use!
@font-face {
  font-family: 'AvenirCondensed';
  src: url('/assets/fonts/Avenir-Condensed/avenircondensed-regular.eot');
  src: url('/assets/fonts/Avenir-Condensed/avenircondensed-regular.woff2') format('woff2'),
    url('/assets/fonts/Avenir-Condensed/avenircondensed-regular.woff') format('woff'),
    url('/assets/fonts/Avenir-Condensed/avenircondensed-regular.ttf') format('truetype'),
    url('/assets/fonts/Avenir-Condensed/avenircondensed-regular.svg#AvenirCondensed') format('svg'),
    url('/assets/fonts/Avenir-Condensed/avenircondensed-regular.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}
//----------------------Avenir----------------------------------------------
@font-face {
  font-family: 'Avenir';
  font-weight: normal;
  font-style: normal;
  src: url('/assets/fonts/AvenirLT/avenirlt-roman.woff2') format('woff2'), url('/assets/fonts/AvenirLT/avenirlt-roman.woff') format('woff'),
    url('/assets/fonts/AvenirLT/avenirlt-roman.ttf') format('ttf'), url('/assets/fonts/AvenirLT/avenirlt-roman.eot') format('eot');
}

@font-face {
  font-family: 'Avenir';
  font-weight: medium;
  font-style: normal;
  src: url('/assets/fonts/AvenirLT/avenirlt-medium.woff2') format('woff2'),
    url('/assets/fonts/AvenirLT/avenirlt-medium.woff') format('woff'), url('/assets/fonts/AvenirLT/avenirlt-medium.ttf') format('ttf'),
    url('/assets/fonts/AvenirLT/avenirlt-medium.eot') format('eot');
}
@font-face {
  font-family: 'Avenir';
  font-weight: 900;
  font-style: normal;
  src: url('/assets/fonts/AvenirLT/avenirlt-heavy.woff2') format('woff2'), url('/assets/fonts/AvenirLT/avenirlt-heavy.woff') format('woff'),
    url('/assets/fonts/AvenirLT/avenirlt-heavy.ttf') format('ttf'), url('/assets/fonts/AvenirLT/avenirlt-heavy.eot') format('eot');
}

//-------------------------------ALSSchlangesans-------------------------------------------
@font-face {
  font-family: ALSSchlangesans;
  src: url('/assets/fonts/ALSSchlangesans/ALSSchlangesans.eot');
  src: url('/assets/fonts/ALSSchlangesans/ALSSchlangesans.woff2') format('woff2'),
    url('/assets/fonts/ALSSchlangesans/ALSSchlangesans.woff') format('woff'),
    url('/assets/fonts/ALSSchlangesans/ALSSchlangesans.ttf') format('truetype'),
    url('/assets/fonts/ALSSchlangesans/ALSSchlangesans.svg#ALSSchlangesans') format('svg'),
    url('/assets/fonts/ALSSchlangesans/ALSSchlangesans.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: ALSSchlangesans;
  src: url('/assets/fonts/ALSSchlangesans-Bold/ALSSchlangesans-Bold.eot');
  src: url('/assets/fonts/ALSSchlangesans-Bold/ALSSchlangesans-Bold.woff2') format('woff2'),
    url('/assets/fonts/ALSSchlangesans-Bold/ALSSchlangesans-Bold.woff') format('woff'),
    url('/assets/fonts/ALSSchlangesans-Bold/ALSSchlangesans-Bold.ttf') format('truetype'),
    url('/assets/fonts/ALSSchlangesans-Bold/ALSSchlangesans-Bold.svg#ALSSchlangesans-Bold') format('svg'),
    url('/assets/fonts/ALSSchlangesans-Bold/ALSSchlangesans-Bold.eot?#iefix') format('embedded-opentype');
  font-weight: bold;
  font-style: normal;
}
//
