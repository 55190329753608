body {
  background-color: #ffffff;
  font-family: Avenirnextcyr, sans-serif;
  color: #353535;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

h1 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
}

h2 {
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
  text-align: center;
}

h3 {
  margin-top: 0px;
  margin-bottom: 15px;
  font-size: 18px;
  line-height: 23px;
  font-weight: 400;
}

h4 {
  margin-top: 0px;
  margin-bottom: 10px;
  color: rgba(53, 53, 53, 0.8);
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
}

p {
  margin-bottom: 10px;
  color: rgba(53, 53, 53, 0.8);
}

a {
  color: $blue_text;
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  color: #5d7aff;
}

ul {
  margin-top: 0px;
  margin-bottom: 10px;
  padding-left: 40px;
}

img {
  display: inline-block;
  max-width: 100%;
}

figure {
  margin-bottom: 10px;
}

.line {
  height: 2px;
  margin-top: 50px;
  margin-bottom: 55px;
  background-color: rgba(53, 53, 53, 0.05);
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.container {
  position: relative;
  display: block;
  width: 1150px;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;
}

.container.prof-page-cont {
  width: 1200px;
  padding-right: 50px;
  padding-left: 50px;
}

.container.container-845 {
  width: 845px;
}

.container.container-900 {
  width: 1000px;
  text-align: center;
}

.vertical-container {
}

.content-section {
  overflow: hidden;
  padding-left: 100px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.content-section.testing-content {
  overflow: hidden;
  position: relative;
  padding-top: 35px;
  padding-bottom: 0;
  padding-left: 0px;
}

.content-section.testing-content-2 {
  position: relative;
  margin-top: -25px;
  padding-top: 0px;
  padding-bottom: 80px;
  padding-left: 0px;
}

.vertical-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.h1-div {
  padding-top: 5px;
  padding-right: 50px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.left-aligned {
  text-align: left;
}

.h1-div._2 {
  padding-top: 0px;
}

.div-space {
  height: 0px;
}

.div-space-2 {
  display: none;
  height: 0px;
}

.div-space-3 {
  height: 0px;
}

.test-title {
  color: #353535;
  font-size: 18px;
  line-height: 23px;
  font-weight: 400;
}

.empty-block {
  padding-top: 90px;
  padding-bottom: 100px;
  border-top: 2px solid rgba(53, 53, 53, 0.05);
  text-align: center;
}

.empty-block-text {
  display: inline-block;
  width: 285px;
  max-width: 100%;
}

.professions-heading {
  font-size: 35px;
  line-height: 40px;
}

.professions-heading.results-heading {
  margin-bottom: 30px;
}

.results-container {
  padding: 40px 32px 44px;
  border-radius: 12px;
  background-color: $color-white;
  -webkit-box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.05);
  @media screen and (max-width: 450px) {
    padding: 0;
    background-color: initial;
    box-shadow: none;
  }
}

.text-block {
  display: inline-block;
}
.text-block-2 {
  margin-top: 40px;
}

.error-text-field {
  position: absolute;
  left: 2px;
  margin-top: -7px;
  color: #fd651a;
  font-size: 10px;
  line-height: 15px;
}

.arrow-link {
  position: absolute;
  top: 50%;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 40px;
  margin-left: -10px;
  padding-right: 3px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100px;
  background-color: #9375fb;
  box-shadow: 0 5px 20px 0 rgba(147, 117, 251, 0.3);
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
}

.arrow-link.next {
  right: 0px;
  margin-right: -10px;
  padding-right: 0px;
  padding-left: 3px;
}

.arrow-link.hide {
  display: none;
}

.scrollbar {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  width: 8px;
}

.scrollbar-handle {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 85px;
  border-radius: 50px;
  background-color: #d8d8d8;
}

.mobile-block {
  display: none;
}

.event-empty-block-text {
  display: inline-block;
  width: 145px;
  max-width: 100%;
}
.event-empty-block {
  padding-top: 90px;
  padding-bottom: 100px;
  text-align: center;
}

.results-empty-block {
  padding-top: 90px;
  padding-bottom: 100px;
  text-align: center;
  min-height: 65vh;
}
.professions-description {
  margin-bottom: 0px;
  color: rgba(53, 53, 53, 0.5);
  font-size: 15px;
}

.task-result-text {
  margin-bottom: 35px;
  color: rgba(53, 53, 53, 0.5);
  font-size: 14px;
}

.link {
  padding: 10px;
  color: $indigo;
  text-decoration: none;
  position: relative;
  display: inline-block;
  font-weight: 600;
  background-color: transparent;
  &:after {
    box-sizing: border-box;
    border-bottom: 1px solid $indigo;
    opacity: 0.3;
    display: block;
    content: '';
    position: absolute;
    bottom: 0;
    top: 1rem;
    left: 0;
    width: 0%;
    transition: width 0.3s ease;
  }
  &:hover {
    cursor: pointer;
    color: #716aca;
  }
  @media (max-width: 767px) {
    display: block !important;
    text-align: center;
  }
}

.white-text {
  color: #fff;
}

.event-h1 {
  margin-bottom: 35px;
  font-size: 35px;
  line-height: 40px;
}

.event-h1.edit {
  margin-bottom: 10px;
}

.event-h2 {
  margin-top: 5px;
  margin-bottom: 40px;
  font-size: 25px;
  text-align: left;
}

.inline-text {
  display: inline;
}

.subtitle-h1 {
  color: rgba(53, 53, 53, 0.5);
  font-size: 13px;
  line-height: 15px;
}

.container-970 {
  max-width: 970px;
}

ul li {
  list-style: none;
  padding: 0;
  cursor: default;
}

.event-empty-block {
  margin: 0 auto;
}

// metro colors
.event-metro-row,
.event-categories-link {
  .red {
    background-color: red !important;
  }

  .green {
    background-color: green !important;
  }

  .blue {
    background-color: blue !important;
  }

  .light-blue {
    background-color: #019ee0 !important;
  }

  .orange {
    background-color: orange !important;
  }

  .purple {
    background-color: purple !important;
  }

  .yellow {
    background-color: yellow !important;
  }

  .lime {
    background-color: #b1d332 !important;
  }

  .white-red {
    background-color: wheat !important;
  }

  .tail {
    background-color: #78cdcd !important;
  }

  .blue-grey {
    background-color: #acbfe1 !important;
  }

  .brown {
    background-color: #884d35 !important;
  }

  .grey {
    background-color: grey !important;
  }

  .purple-light {
    background-color: #9999ff !important;
  }
}

.mat-snack-bar-container.error {
  background-color: #fd651a;
  border-radius: 8px !important;
  box-shadow: 0 5px 30px rgba(253, 101, 26, 0.15);
}

.mat-snack-bar-container.success {
  background-color: #36ca75;
  border-radius: 8px !important;
  box-shadow: 0 5px 30px rgba(54, 202, 117, 0.15);
}

//====================================
//===============MEDIA================
//====================================
@media screen and (max-width: 991px) {
  .content-section.testing-content {
    padding-left: 0px;
  }
  .content-section.testing-content-2 {
    position: static;
    margin-top: 0px;
    padding-top: 40px;
    padding-left: 0px;
  }

  .container-970.slider-heading-container {
    padding-right: 20px;
    padding-left: 20px;
  }
  .text-block {
    display: inline-block;
  }
  .arrow-link {
    display: none;
  }
  .mobile-block {
    display: block;
  }
  .event-empty-block {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .event-h2 {
    margin-bottom: 30px;
  }
  .sources.lb.mob {
    overflow: visible;
  }
}
//============================768px======================
@media screen and (max-width: 768px) {
  .content-section {
    padding-top: 0px;
    padding-left: 0px;
  }
  .container {
    padding-top: 12px;
    padding-right: 20px;
    padding-left: 20px;
  }
  .h1-div {
    padding-top: 14px;
    padding-right: 30px;
    padding-left: 30px;
  }
  .h1-div._2 {
    padding-top: 7px;
  }

  .container.prof-page-cont {
    padding-right: 20px;
    padding-left: 20px;
  }
  .div-space {
    display: none;
  }
  .div-space-2 {
    display: block;
  }
  .error-text-field {
    position: static;
    margin-left: 2px;
  }
  .arrow-link.next {
    margin-right: 0px;
  }
  .arrow-link.hide {
    margin-left: 0px;
  }
  // .professions-description {
  //   display: none;
  // }
  .admin-section {
    margin-top: 10px;
    margin-right: -20px;
    margin-left: -20px;
  }
  .event-h1 {
    font-size: 30px;
    line-height: 35px;
  }
  .subtitle-h1 {
    font-size: 11px;
  }
}

//======================450px===================
@media screen and (max-width: 450px) {
  .container {
    padding-top: 0;
  }
  .content-section.testing-content {
    padding-top: 0;
  }
  .content-section.testing-content-2 {
    padding-top: 20px;
  }
  .professions-description {
    -webkit-line-clamp: 5;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
